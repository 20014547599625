<template>
    <div :class="field.divclass" :style="field.divstyle">
        <template v-if="field.type === 'BOOL'">
            <BoolField :field="field" :data="data" :readonly="readonly" :state="state"
                       v-on:change="inlineEdit(data)"/>
        </template>
        <template v-else-if="field.type === 'COLOR'">
            <span :key="'field-'+field.id"
                  class="dot" :class="{'mr-1':!field.compact}" :style="'background-color:' + data[field.name].value" :title="data[field.name].label"
                  v-if="(field.name in data) && ('value' in data[field.name]) && data[field.name].value"/>
            <template v-if="!field.compact">
                {{ data[field.name].label }}
            </template>
        </template>
        <template v-else-if="field.type === 'COLORLIST'">
            <template v-for="(color,colorIndex) in data[field.name]">
                <span :key="'field-'+field.id+'-'+colorIndex" class="dot mr-1" :style="'background-color:' + color.value" :title="color.label"/>
            </template>
        </template>
        <template v-else-if="field.type === 'DATALIST'">
            <template v-if="data[field.name]">
                {{ data[field.name] }}
            </template>
        </template>
        <template v-else-if="field.type === 'DATE'">
            <template v-if="inlineEditable">
                <b-form-datepicker size="sm" v-model="data[field.name]"
                                   :class="field.class" :style="field.style" :disabled="state.disabled"
                                   :placeholder="field.placeholder ? field.placeholder : $i18n.t('translations.Date')"
                                   :state="field.statefield ? data[field.statefield] : field.state"
                                   :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
                                   :start-weekday="field.startweekday ? field.startweekday : 1"
                                   :initial-date="field.initialdate ? field.initialdate : (field.initialdatefield ? data[field.initialdatefield] : null)"
                                   today-button reset-button close-button boundary="window" locale="be"
                                   v-on:input="inlineEditDate(field, data)"/>
            </template>
            <template v-else-if="data[field.name]">
                {{ formattedDate() }}
            </template>
        </template>
        <template v-else-if="field.type === 'DATETIME'">
            {{ formattedDate() }}
        </template>
        <template v-else-if="field.type === 'ENUM'">
            <template v-if="inlineEditable">
                <b-form-select :key="'field-'+field.id" :size="field.size" plain
                               :class="field.classfield ? data[field.classfield] : field.class"
                               :style="field.stylefield ? data[field.stylefield] : field.style"
                               :options="field.optionsfield ? data[field.optionsfield] : field.options" v-model="data[field.name]"
                               v-on:change="inlineEdit(data)"/>
            </template>
            <template v-else>
                {{ enumTextByValue(field, data) }}
            </template>
        </template>
        <template v-else-if="field.type === 'ID'">
            <input v-model="data.selected" type="checkbox" v-on:change="$emit('toggle-row')">
        </template>
        <template v-else-if="field.type === 'INPUTTYPE'">
            <font-awesome-icon v-if="data[field.name] === 1" :icon="['fas', 'circle']" aria-hidden="true" style="color:#5cb85c;" title="NO_PRODUCT"/>
            <font-awesome-icon v-else-if="data[field.name] === 2" :icon="['fas', 'circle']" aria-hidden="true" style="color:#5bc0de;" title="PRODUCT"/>
            <font-awesome-icon v-else-if="data[field.name] === 3" :icon="['fas', 'circle']" aria-hidden="true" style="color:#337ab7;" title="SKETCHUP_PRODUCT"/>
            <font-awesome-icon v-else-if="data[field.name] === 4" :icon="['fas', 'circle']" aria-hidden="true" style="color:#d9534f;" title="SKETCHUP_NO_PRODUCT"/>
            <font-awesome-icon v-else :icon="['fas', 'circle']" aria-hidden="true" style="color:#f0ad4e;" title="?"/>
        </template>
        <template v-else-if="field.type === 'JSON'">
            <b-form-textarea :class="field.class" :style="field.style" :rows="field.rows" :max-rows="field.maxrows" v-text="data[field.name]"
                             size="sm" no-auto-shrink no-resize plaintext disabled/>
        </template>
        <template v-else-if="field.type === 'LINK'">
            <router-link :to=routeTo :class="field.class" :style="field.style">
                {{ data[field.name] ? data[field.name] : field.label }}
            </router-link>
        </template>
        <template v-else-if="field.type === 'NUMERIC'">
            <template v-if="inlineEditable">
                <NumericField :key="'field-'+field.id" :data="data" :field="field" :state="state"
                              v-on:input="inlineEdit"
                              v-on:forward-event="$emit('forward-event',$event)"/>
            </template>
            <template v-else>
                {{ data[field.name] }}
            </template>
        </template>
        <template v-else-if="field.type === 'RELATION'">
            <template v-if="inlineEditable">
                <SelectField :key="'field-'+field.id" :data="data" :field="field" :state="state"
                             v-on:input="inlineEdit"/>
            </template>
            <template v-else-if="data[field.name] && data[field.name][field.display]">
                {{ data[field.name][field.display] }}
            </template>
        </template>
        <template v-else-if="field.type === 'RICHTEXT'">
            <RichTextField :key="'field-'+field.id" :field="field" :data="data" :state="state" readonly/>
        </template>
        <template v-else-if="field.type === 'TEXT'">
            <div>{{ data[field.name] }}</div>
            <template v-if="field.commentfield">
                <div style="font-size: xx-small; font-style: italic; padding-left: 10px; white-space: pre-wrap;">{{ data[field.commentfield] }}</div>
            </template>
        </template>
        <template v-else-if="field.type === 'TEXTAREA'">
            <div style="white-space: pre-wrap;">{{ data[field.name] }}</div>
        </template>
        <template v-else-if="field.type === 'TRANSLATABLE'">
            {{ data[field.display] }}
        </template>
        <template v-else>
            {{ data[field.name] }}
        </template>
    </div>
</template>

<script>
import BoolField from "@/components/fields/BoolField.vue";
import moment from "moment";
import NumericField from "@/components/forms/fields/NumericField.vue";
import RichTextField from "@/components/forms/fields/RichTextField.vue";
import SelectField from "@/components/forms/fields/SelectField.vue";

export default {
    name: "Field",
    components: {
        BoolField,
        NumericField,
        RichTextField,
        SelectField,
    },
    props: ["field", "data", "readonly", "state"],
    computed: {
        routeTo: function () {
            let name = this.field.routenamefield ? this.data[this.field.routenamefield] : this.field.routename;
            let params = this.field.routeparamsfield ? this.data[this.field.routeparamsfield] : this.field.routeparams;
            return {
                name: name,
                ...(params && {params: params})
            };
        },
        inlineEditable: function () {
            return (
                !this.readonly &&
                !this.state.readonly &&
                (this.field.editintable || (this.field.editintablefield && this.data[this.field.editintablefield]))
            );
        },
    },
    methods: {
        enumTextByValue(field, data) {
            let options = field.optionsfield ? data[field.optionsfield] : field.options;
            let object = options.find(o => o.value === data[field.name]);
            return object ? object.text : '';
        },
        formattedDate() {
            if (this.data[this.field.name]) {
                if (this.field.type === 'DATE') {
                    return moment(String(this.data[this.field.name])).format('DD/MM/YYYY');
                } else {
                    return moment(String(this.data[this.field.name])).format('DD/MM/YYYY HH:mm');
                }
            }
        },
        inlineEdit(data) {
            if (this.field.editintableapi || this.field.editintableapifield) {
                this.state.loading = true;
                let payload = null;
                switch (this.field.type) {
                    case 'BOOL':
                    case 'DATE':
                    case 'ENUM':
                        payload = {
                            [this.field.name]: data[this.field.name],
                        };
                        break;
                    case 'RELATION':
                        payload = {
                            [this.field.name]: data['id'],
                        };
                        break;
                }
                let api = this.field.editintableapi ? this.field.editintableapi : this.data[this.field.editintableapifield];
                this.$http.put(api + '/' + this.data.id, payload, {}).then((res) => {
                    this.$emit('inline-edit', res.data);
                }).catch((error) => {
                    console.log("Field:inlineEdit():error:", error);
                });
            } else {
                this.$emit('inline-edit', data);
            }
        },
        inlineEditDate(field, data) {
            if (data[field.name] === "") {
                data[field.name] = null;
            }
            this.inlineEdit(data);
        },
    },
}
</script>

<style scoped>
.dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
}
</style>
